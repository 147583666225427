<template>
    <div class="auto-complete-box" >
        <input ref="input" :value="value"  
                @input="handleInputChange($event)"
                :placeholder="placeholder"
                @blur="handleBlur"
                @focus="handleFocus"
                @keydown.exact.enter="handleReturn"
                @keypress="queryChange"
                @keydown.backspace="queryChange"
                @keydown.exact.up="sugUpdate(-1,$event)"
                @keydown.exact.down="sugUpdate(1,$event)"
                >
    <div class="serp-suggestions"  v-if="hasResults && hasFocus"  >
        <div class="serp-suggestion"  :class="{'active': idx == realSelected}"
            @click="handleClick(s,$event)"
            v-for="(s,idx) in items"  >
            <div class="sug-info" >
                <h4 class="sug-title" v-html="s.text"  ></h4>
            </div>
        </div>
    </div>
    </div>
</template>
<script>
"use strict"

import _ from "lodash"

export default {
    props:["value","provider","placeholder"],
    data(){
        return {
            results:false,
            selected:false,
            errors:false,
            hasFocus:false,
            issued:false
        }
    },
    methods:{
        handleInputChange(e){
            this.$emit('input',e.target.value);
        },
        focus(){
            this.$refs.input.focus()
        },
        blur(){
            this.$refs.input.blur()
        },
        handleBlur(){
            window.setTimeout(() => { 
                this.hasFocus=false
            },200);
            this.$emit('blur');
        },
        handleFocus(){
            this.$nextTick(() => {
                this.hasFocus=true
            })
            this.$emit('focus')
        },
        handleReturn(){
            //If something is selected we will pick it.
            if(this.selected !== false){
                let elt =this.items[this.realSelected];
                this.$emit('input',elt.text)
                this.$emit("selected");
            }else {
                this.$emit("return");
            }
        },
        handleClick(item,e){
            this.$emit("input",item.text)
            this.$emit("selected");

        },sugUpdate(count,e){
            e.preventDefault()
            if(count == false) {
                this.selected = false
                return;
            }else if(this.selected === false){
                this.selected = count <0?-1:0;
                return
            }
            this.selected += count;
        },
        queryChange: async function(){
            if(this.issued){
                this.issued=false;
                this.request(false);
                return;
            }
            if(this.results){
                let {query} = this.results
                if(query.length >  this.value.length ){ 
                    this.clear();
                }
            }
            this.request();
        },
        clear(){
            this.results=false;
            this.selected=false;
        },
        request:_.debounce(async function(cancel){
            let term = _.trim(this.value);
            let lastResult = this.results;
            if(term === "" || cancel){
                this.clear();
                return;
            }
            console.log("REquesting ->",term);
            let res =await this.provider(term)
            let {query} = res;
            if(this.value.indexOf(term) == -1){
                if(lastResult == this.results){
                    this.clear();
                }
                return;
            }
            this.results=res
        },300)
    },
    computed:{
        hasResults(){
            return this.results && this.items.length  > 0
        },
        realSelected(){
            if(this.selected === false) return -1;
            if(this.items === false) return -1;
            return this.selected % this.items.length;
        },
        items(){
            return  this.results && this.results.results;
        }
    }
}
</script>
<style>
.auto-complete-box {
    display:inline;
}
</style>
