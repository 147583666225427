
const SEARCH_API =  "/api/v1/search";
const JOB_SEARCH_API = "/api/v1/search-alternative/";
const SUGGESTION_API = "/api/v1/complete";


let  TOP_DOMAIN = (typeof branding !== "undefined" && branding.RootDomain) || (function(){
    let parts=window.location.hostname.split(/\./);
    let domain = parts.length == 1?window.location.hostname:parts.slice(-2).join(".")
    return (domain + (window.location.port?(":"+window.location.port):"")).toLowerCase();
})();

const  WEB_SEARCH_URL = `https://services.${TOP_DOMAIN}/crx/search.php`;
const CURRENT_LOCATION_URL = `https://ip2.${TOP_DOMAIN}/`

function openUrl(url, newtab = true) {
    window.open(url, newtab ? "_blank" : null);
}

export {
    SEARCH_API,
    JOB_SEARCH_API,
    SUGGESTION_API,
    TOP_DOMAIN,
    CURRENT_LOCATION_URL,
    WEB_SEARCH_URL,
    openUrl,
}
