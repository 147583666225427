"use strict"

/**
 * Common functions and state management
 */
import _ from "lodash"
import Promise from "bluebird"

const branding = require("./branding").getBranding()

const MAX_RECENT_ITEMS = 10;

export let documentReady = new Promise(resolve => {
    if (document.readyState === 'complete') {
        resolve(true);
        return 
    }
    document.addEventListener("DOMContentLoaded",function(){
        resolve(true);
    })
})




export function getRecentSearches(){
    try{
        let lst=window.localStorage.recentSearches
        if(lst) return JSON.parse(lst) 
    }catch(e){
        console.log("Error with recent searches",e);
    }
    return []
}

export function saveRecentSearches(recentSearches){
    window.localStorage.recentSearches =  JSON.stringify(recentSearches);
}

export function addRecentSearch(recentSearches,recent){
    let existing = _.findIndex(recentSearches,x => {
        return x.query == recent.query  && x.location == recent.location
    })
    if(existing != -1){//Move to the front
        let removed=recentSearches.splice(existing,1);
        recentSearches.unshift(...removed);
    }else {
        recentSearches.unshift(recent);
    }
    if(recentSearches.length >  MAX_RECENT_ITEMS){
        recentSearches.length = MAX_RECENT_ITEMS
    }
}


export let JOB_SEARCH_PROVIDERS = [
    {name:branding.SearchEngineName,id:branding.SearchId,isDefault:true},
    {name:"Indeed",id:"indeed"},
    {name:"Monster",id:"monster"},
    {name:"Career Builder",id:"careerBuilder"}
]

export let WEB_SEARCH_PROVIDERS = [
    {name:"Yahoo",id:"yahoo"},
    {name:"Google",id:"google"},
    {name:"Bing",id:"bing"}
]

export const isFirefox = navigator.userAgent.includes("Firefox");
