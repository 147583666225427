<template>
    <input ref="location" :placeholder='placeholder' :value="value"
    v-on="inputListeners" />
</template>
<script>

import {LOCATION_OPTIONS,mapsReady,titleSuggestionProvider} from '../autocomplete'

export default {
    props:["value","placeholder"],
    data(){
        return {
            setup:false
        }
    },
    computed:{
        inputListeners(){
            var vm=this;
            return {
                ...this.$listeners,
                input(event){
                    vm.$emit('input',event.target.value);
                }
            }
        }
    },
    methods:{
        handleInputChange(e){
            this.$emit('input',e.target.value);
        },
        focus(){
            this.$refs.location.focus();
        },
        blur(){
            this.$refs.location.blur();
        }
    },
    mounted(){
        let vm=this;
        (async () => {
            await mapsReady;
            let google = window.google
            let locWidget = new google.maps.places.Autocomplete(this.$refs.location,
                LOCATION_OPTIONS);
            locWidget.addListener("place_changed",() => {
                vm.$emit('input',locWidget.getPlace().name);
            })

        })();
    },
    destroy(){
        if(!this.setup) {
            this.setup=true; //Prevent later initialization
            return
        }
        
        
    }
}
</script>
