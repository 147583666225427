<template>
    <!--main wrapper-->
    <div class="serp-wrapper">
        <!-- Bookmark Popup  -->
        <first-run :which="whichPop" :onClose="onCloseHelp" :onNext="onNextPop" :currentLocation="this.location" :currentQuery="this.query" />
        <!-- First Run Popup -->
        <!--header wrapper-->
        <div class="header-wrapper">
            <!--nav-->
            <div id="serp-nav">
                <div id="job-btn" class="serp-nav-item"
                @click="toJobSearch"
                :class="{active: jobSearch}" >Job Search</div>
                <div id="web-btn" class="serp-nav-item "
                v-if="!isFirefox"
                @click="toWebSearch"
                :class="{active: !this.jobSearch}" >Web Search</div>
            </div>
            <!--End of nav-->
            <!--header-->
            <div class="serp-header">
                <div class="serp-logo"><img :src="branding.Logo"></div>
                <div class="jobSearch" v-if="jobSearch" >
                    <div class="search pad-right">
                        <auto-complete-input id="" v-model="query" ref="jobQuery" placeholder="Search job title or keyword"
                        :provider="titleSuggestionProvider"
                        @return="onJobReturn()"
                        @selected="onSugSelected()"
                        @blur="onLeaveSearch()"
                        @focus="onEnterSearch()"
                        />
                        <div class="s-icon"><i class="fa fa-briefcase" aria-hidden="true"></i></div>
                        <div class="error"
                        v-if="queryError && queryError.query"
                        >{{queryError.query}}</div>
                    </div>
                    <div class="search">
                        <places-complete-input id="" v-model="location" placeholder="Location"
                        ref="jobLocation"
                        @keydown.enter="onLocReturn()"
                        @blur="onLeaveLoc" @focus="onEnterLoc"
                        />
                        <div class="s-icon"><i class="fa fa-map-marker" aria-hidden="true"></i></div>
                        <div class="error"
                        v-if="queryError && queryError.location"
                        >{{queryError.location}}</div>
                    </div>
                    <button id="btn-search" @click="clickStartSearch()"> Find Jobs</button>
                </div>
                <div class="webSearch" v-else >
                    <div class="search web">
                        <input id="" ref="webSearch" v-model="searchQuery"
                        @keydown.enter="startWebSearch()"
                        placeholder="Search the web"/>
                        <div class="s-icon"><i class="fa fa-globe" aria-hidden="true"></i></div>
                        <div class="error" v-if="queryError && queryError.query" >{{queryError.query}}</div>
                    </div>
                    <button id="btn-search"
                    @click="startWebSearch()" >Web Search</button>
                </div>
            </div>
            <!--End of Header-->
        </div>
        <!--End of header wrapper-->

        <!--Body Wrapper-->
        <div class="bodyWrapper">
            <!--serp results-->
            <div class="serp-results">
                <!--Right col -->
                <div class="rightCol">
                    <!--Filter-->
                    <div class="module-right filter" v-show="false">
                        <h2><i class="fa fa-filter" aria-hidden="true"></i>Filter By:</h2>
                        <div class="duration custom-select">
                            <h3>Date posted:</h3>
                            <select v-model="age" >
                                <option :value="false">None</option>
                                <option :value="startTimeUnit('day')">Today</option>
                                <option :value="startTimeUnit('week')">This Week </option>
                                <option :value="startTimeUnit('month')">This Month</option>
                            </select>
                        </div>
                        <div class="company" style="display:none">
                            <h3>Company:</h3>
                            <select>
                                <option value="volvo">Volvo</option>
                                <option value="saab">Saab</option>
                                <option value="mercedes">Mercedes</option>
                                <option value="audi">Audi</option>
                            </select>
                        </div>
                    </div><!--End of filter-->
                    <!--Search History-->
                    <div class="module-right side-list history">
                        <h2><i class="fa fa-history" aria-hidden="true"></i>Recent searches</h2>
                        <div class="list" v-for="item in recentSearches" :title="item.query + ' @ ' + item.location"
                        @click="openRecentSearch(item)"
                        >{{item.query}}</div>
                    </div><!--end of search history-->
                    <!--Bookmark-->
                    <div class="module-right side-list bookmark" style="display:none">
                        <h2><i class="fa fa-bookmark" aria-hidden="true"></i>Bookmark</h2>
                        <div class="list">Product Developer</div>
                        <div class="list">Product Developer</div>
                        <div class="list">Product Developer</div>
                        <div class="list">Product Developer</div>
                    </div><!--end of Bookmark-->
                </div>
                <!--End of Right col-->
                <!--Left col-->
                <div class="serp-results-left">
                    <div class="serp-stats" v-if="totalResults" >Showing {{firstRecNo}} to {{lastRecNo}} of {{totalResultsFmt}} Jobs for
                        <span class="kw">'{{lastQuery}}'</span> in <span class="loca">"{{lastLocation}}"</span></div>
                        <!--NoResults-->
                        <div class="serp-noresults" v-if="isNoResults">
                            <h3>The search <span class="kw">{{lastQuery}}</span> did not match any jobs. Try the suggestions below or type a new query above.</h3>
                            <ul>
                                <li>Check your spelling.</li>
                                <li>Try more general keywords</li>
                                <li>Replace abbreviations with the entire word</li>
                            </ul>
                        </div>
                        <!-- End of NoResults-->
                        <!--results has image-->
                        <div class="srl has-image"
                        v-for="r in records"
                        v-if="records && records.length"
                        @click.stop.prevent="handleJobClick($event)"
                        >
                        <div class="srl-book" style="display:none">
                            <span  tooltip="Bookmark" class="tooltip" flow="right"></span></div>
                            <div class="srl-img"><img :src="r.logo || '/assets/images/no-img.png'"></div>
                            <div class="srl-info">
                                <a class="srl-link-a" style="display:none" :href="r.url" target="_blank" >--</a>
                                <h3 class="srl-title">{{r.jobtitle}}</h3>
                                <div class="srl-link">{{r.company}} - {{r.city}}, {{r.country}} {{r.zip}}</div>
                                <div class="srl-content">{{r.snippet}}</div>
                                <div class="srl-stamp">{{r.date | reltime}}</div>
                            </div>
                        </div>
                        <!--end of results has image-->
                    </div>
                    <!--End of left col-->
                    <!--right col-->
                    <div class="serp-results-right">
                        Ads
                    </div>
                    <!--End of right col-->
                </div>
                <!--End of resultes-->
            </div>
            <!--End of body wrapper-->

            <!--pagination-->
            <div class="serp-pagination" v-if="totalResults" >
                <a class="prev" :class="{hidden: pageNo == 0 }"
                @click="prevPage()" >Prev</a>
                <a class="page" v-bind:class="{active: (pageNo +1) == current }" v-for="current in pageNumbers"
                @click="toPage(current-1)" >{{current}}</a>
                <a class="next"
                v-bind:class="{ hidden:pageNo >= (this.numPages - 1 ) }"
                @click="nextPage()"
                >Next</a>
            </div>
            <!--End of pagination-->

            <!--footer-->
            <footer>
                <div class="serp-footer">
                    <div class="logoFooter"><img :src="branding.GrayLogo"><span><i class="fa fa-copyright" aria-hidden="true"></i>{{branding.Trademark}}</span></div>
                    <ul>
                        <li><a :href="`https://${branding.RootDomain}/terms-of-service/`">Terms of Service</a></li>
                        <li><a :href="`https://${branding.RootDomain}/privacy-policy/`">Privacy</a></li>
                        <li><a :href="`https://${branding.RootDomain}/privacy-policy/#cali-consumer`" target="_blank">Do Not Sell My Info</a></li>
                    </ul>
                </div>
            </footer>
            <!--end of footer-->
        </div>
        <!-- End of main wrapper -->
    </div>
</template>
<script>
"use strict"
import bp  from "babel-polyfill"
import queryString  from 'query-string'
import 'whatwg-fetch'
import Humanize from 'humanize-plus'
import _ from 'lodash'
//import moment from 'moment'
import {getSetting, setSetting, getGuid} from "./storage"
import {SEARCH_API,WEB_SEARCH_URL,SUGGESTION_API, CURRENT_LOCATION_URL, openUrl}  from './urls'
import {getRecentSearches,addRecentSearch,documentReady,saveRecentSearches, isFirefox} from './common'
import {LOCATION_OPTIONS,mapsReady,titleSuggestionProvider} from './autocomplete'
import FirstRun from './components/FirstRun.vue'
import AutoCompleteInput from './components/AutoCompleteInput.vue'
import PlacesCompleteInput from './components/PlacesCompleteInput'
import {getBranding}  from "./branding"


function validatePop(name){
    switch(name){
        case "intro":
        case "i":
        return "intro"
        case "t":
        case "tutorial":
        return "tutorial"
        case "it":
        return "intro-tutorial"
    }
    return false;
}

export default {
    name: 'serp',
    components:{
        'first-run':FirstRun,
        'auto-complete-input':AutoCompleteInput,
        'places-complete-input':PlacesCompleteInput
    },
    data(){
        let qs  = queryString.parse(window.location.search);
        return {
            query:'',
            searchQuery:'',
            location:'',
            age:false,
            jobSearch:true,
            queryError:false,
            guid:qs.guid ,
            pageNo:parseInt(qs.start) || 0,
            perPage:10,
            searchResults:false,
            searchFocus:false,
            locFocus:false,
            searchError:false,
            searchIssued:false, //Has a search due to the return key been issued
            sugPerPage:5,
            sugResults:false,
            sugSelected:false,
            //sugState:SUG_NONE,
            sugError:false,
            whichPop:validatePop(qs.pop) || false ,
            ///Recent Searchs
            recentSearches: [] ,
            branding:getBranding(),
            isFirefox,
        }
    },
    mounted(){
        documentReady.then( async () => {
            this.$refs.jobQuery.focus();
            
            await getGuid().then(x => {
                this.guid =x;
            }).catch(_ => false);

        })

    },
    created(){
        this.handleSearchUrl();
        window.onpopstate = ()=>{
            this.handleSearchUrl();
        }
        try{
            this.recentSearches =  getRecentSearches()
        }catch(e){
        }
    },
    filters:{
        reltime(value){
            return  moment(value,'YYYY-MM-DD').fromNow()
        }
    },
    watch:{
        recentSearches(value){
            saveRecentSearches(this.recentSearches);
        }
    },
    computed: {
        lastQuery(){
            return this.result('query')
        },
        lastLocation(){
            return this.result('location')
        },
        records(){
            return this.result('results');
        },
        totalResults(){
            return this.result('totalresults')
        },
        isNoResults(){
            return this.totalResults !== false && this.totalResults == 0
        },
        totalResultsFmt(){
            let res=this.totalResults
            return res && Humanize.intComma(res);

        },
        numPages(){
            return this.result('numPages');
        },
        firstRecNo(){
            return (this.pageNo  * this.perPage) +1;
        },
        lastRecNo(){
            return Math.min((this.pageNo + 1) * this.perPage,this.totalResults);
        } ,
        pageNumbers(){
            let perSide = Math.floor(this.perPage/2);
            let minPage=Math.max(this.pageNo - perSide,0);
            let maxPage=   Math.min(this.pageNo  + perSide,this.numPages);
            maxPage= Math.min(Math.max(maxPage,this.perPage),this.numPages)
            let pages=[];
            for(let i=minPage;i<maxPage;++i){
                pages.push(i+1);
            }
            return pages;
        },
        suggestions(){
            return this.sugResults && this.sugResults.records;
        },
        baseUrl(){
            return window.location.href.split('?')[0];
        }
    },
    methods: {
        titleSuggestionProvider,
        result(field){
            return this.searchResults && this.searchResults[field]
        },
        nextPage(){
            this.toPage(this.pageNo + 1);
        },
        prevPage(){
            this.toPage(this.pageNo - 1)
        },
        toPage(num){
            let newPageNo = Math.min(Math.max(0,num),this.numPages-1)
            if(newPageNo != this.pageNo){
                this.pageNo = newPageNo;
                this.startSearch(false)
            }
        },
        onEnterSearch(){
            window.setTimeout(()=>{
                this.searchFocus=true;
            },100);
        },
        onLeaveSearch(){
            window.setTimeout(()=>{
                this.searchFocus=false
            },500);
        },
        onEnterLoc(){
            window.setTimeout(()=>{
                this.locFocus=true;
            },100);
        },
        onLeaveLoc(){
            window.setTimeout(()=>{
                this.locFocus=false
            },500);
        },
        async getCurrentLocation(){
            const req = await fetch(CURRENT_LOCATION_URL)
            if(req.ok){
                const res = await req.json()
                this.location = res.city + ", " + res.stateCode + ", " + res.countryCode2
                return res.city + ", " + res.stateCode + ", " + res.countryCode2
            }
            
        },
        startWebSearch(){
            let searchParams= {
                k:this.searchQuery,
                guid:this.guid
            }
            openUrl(`${WEB_SEARCH_URL}?${queryString.stringify(searchParams)}`);
        },
        clickStartSearch(){
            this.onJobReturn();
        },
        startSearch(reset=true){
            if(reset){
                this.pageNo=0;
            }
            this.addToHistory();
            this.doSearch(this.query,this.location,this.pageNo,this.age);
        },
        async initSearch(){
            if(this.query == '' && this.location == '') {
                let location = await this.getCurrentLocation()
                await this.doSearch(this.query,location,0)
                this.location = this.lastLocation
                return true;
            }
            return false;
        },
        validateJobSearch(withFocus){
            let {query,location} = this;
            let errs=false;
            if(withFocus){
                if(errs.query)  this.$refs.jobQuery.focus();
                else if (errs.location) this.$refs.jobLocation.focus();
            }
            return errs === false;
        },
        onSugSelected(){
            this.$refs.jobLocation.focus();
        },
        onJobReturn(){
            if(!this.validateJobSearch(true)) return
                this.startSearch(true);
        },
        onLocReturn(){
            this.$nextTick(() => {
                if(!this.validateJobSearch(true)) return
                    this.startSearch(true);
            });

        },
        doSearch: async function(term,location,pageNo,age=false){
            var qs = {
                q:term,
                location,
                pageNo,
                perPage:this.perPage
            }
            if(age) qs.age=age;
            let res= await fetch(`${SEARCH_API}?${queryString.stringify(qs)}`);
            let body= await res.json();
            if(this.query !=term ||  this.pageNo != pageNo || this.location !=location || this.age != age) return ;
            if(res.ok){
                this.searchResults = body;
                this.location = this.lastLocation
            }else {
                this.searchError=body;
            }
        },
        addToHistory(){
            let qs  = {
                ...queryString.parse(window.location.search),
                q:this.query,
                l:this.location,
                start:this.pageNo
            }
            delete qs["pop"];
            let histObj={query:this.query,location:this.location,pageNo:this.pageNo}
            if(this.age){
                qs.age = this.age
                histObj.age = this.age
            }
            //Add guid if there is one.
            if(this.guid){ qs.guid=this.guid }
            qs=queryString.stringify(qs);

            let newUrl=this.baseUrl + "?" + qs;
            window.history.pushState(histObj,`${this.query} - ${this.location} / Page # ${this.pageNo}`,newUrl);
            let recent = { query:this.query,location:this.location}
            addRecentSearch(this.recentSearches,recent);
        },
        //Web Search/ Job Search
        toWebSearch(){
            this.jobSearch=false
            this.$nextTick(() => this.$refs.webSearch.focus())
        },
        toJobSearch(){
            this.jobSearch=true;
            this.$nextTick(() =>{
                this.$refs.jobQuery.focus()
            })
        },
        handleSearchUrl(){
            let qs  = queryString.parse(window.location.search);
            let {q,start,l} = qs;
            this.query = q || "";
            this.location =  l || ""
            this.pageNo = parseInt(start) || 0;
            
            if(this.query){
                this.doSearch(this.query,this.location,this.pageNo,this.age);
            }else {
                //initial search to retrieve current location, serves no results
                this.initSearch()
                this.searchResults=false;
            }
            

        },
        startTimeUnit(name){
            return moment().startOf(name).format("YYYY-MM-DD");
        },
        openRecentSearch(elt){
            //WHen the recent search is clicked
            this.query=elt.query;
            this.location= elt.location;
            this.age = elt.age || false
            this.startSearch();
            this.jobSearch = true;

        },
        handleJobClick(evt){
            if(evt.dispatched) return;
            let innerLink=evt.currentTarget.querySelector("a.srl-link-a")
            window.l5_trk(innerLink);
            let newe  = new evt.constructor(evt.type,{
                ...evt,
                bubbles:false
            });
            newe.dispatched=true;
            innerLink.dispatchEvent(newe);
        },
        onNextPop(tag){
            this.whichPop=validatePop(tag) || false;
        },
        onCloseHelp(){
            switch(this.whichPop){
                case "intro-tutorial":
                this.whichPop = validatePop("i");
                return;
            }
            this.whichPop=false
        },
    }
}
</script>

<style>
</style>
