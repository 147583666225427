'use strict'
/**
 * Autocomplete settings and utilities
 */

import P from 'bluebird'
import _ from 'lodash'
import {SUGGESTION_API } from "./urls"
import queryString  from 'query-string'

const ONE_MINUTE_MS = 60*1000;


const LOCATION_OPTIONS= {
    types:["(regions)"],
    fields:["name","place_id"],
    componentRestrictions:{country:"us"}
}

let mapsReady = new P((resolve) => {
    try{
        if(window.google.maps.places.Autocomplete){
            resolve();
            return;
        }
    }catch(E){}
    window.mapsReady = function(){
        resolve(true);
    }
})

//We will setup this to allow a call ever 10 minutes
let clearCache = _.debounce(function(){
    titleSuggestionProvider.cache.clear();
},10*ONE_MINUTE_MS)

let titleSuggestionProvider = _.memoize(async function(term){
    clearCache(); //Make sure the cache doesnt get too big 
    let qs = {
        q:term
    }
    let res= await fetch(`${SUGGESTION_API}?${queryString.stringify(qs)}`);
    if(!res.ok){
        return await res.json().catch(e =>  { 
            return {error:"Bad Response",query:term,results:[]}
        })
    }
    let   data = await res.json();
    return {
        query:term,
        ...data
    };
})



export  {
    LOCATION_OPTIONS,
    mapsReady,
    titleSuggestionProvider
}
