<template>
    <div class="lightbox" v-if="which" >
        <!--Light Box-->
        <!--First Run-->
        <div class="card firstrun" v-if="isIntro" >
            <div @click="onCloseClick" class="close"><i class="fa fa-times" aria-hidden="true"></i></div>
            <img src="/assets/images/js-logo.svg">
            <h1>Thank You For Adding JOBSrch Extension</h1>
            <p>JOBSrch Extension can help you find the best jobs,  Quickly search more than 200k job openings from local employers who want to hire YOU!.</p>
            <div class="btnWrapper">
                <button @click="onCloseClick()" class="ok left">Jobs in {{currentQuery ? currentQuery : currentLocation}}<span id="FirstRunCTA"></span></button>
                <button @click="toNext('it')" class="ok left qt">Quick Tips</button>
            </div>
        </div>
        <!-- End of First Run-->

        <!--Tips-->
        <div class="card firstrun" v-else-if="isTutorial" >
            <div class="close" @click="onCloseClick" ><i class="fa fa-times" aria-hidden="true"></i></div>
            <img src="/assets/images/js-logo.svg">
            <h1>Getting the most out of JOBSrch</h1>
            <img src="/assets/images/type.gif" class="type">
            <ol>
                <li>Go to the address bar and type <strong>"job"</strong></li>
                <li>After <strong>"job"</strong> hit spacebar then the job title you would like to search.<br> (ie. <strong>store manager</strong>)</li>
                <li>Select a recommended job from our curated results or hit enter to get additional jobs results</li>
            </ol>
            <div class="btnWrapper">
                <button class="ok left" v-show="false">Back</button>
                <button class="ok left" @click="onCloseClick()" >OK</button>
            </div>
        </div>
        <!-- End Tips-->
    </div>
</template>
<script>
"use strict";

 import {arrow} from '@tightrope/newtab'

if (!String.prototype.endsWith) {
	String.prototype.endsWith = function(search, this_len) {
		if (this_len === undefined || this_len > this.length) {
			this_len = this.length;
		}
		return this.substring(this_len - search.length, this_len) === search;
	};
}

export default {
    name:'first-run',
    props:["which","onClose","onNext", "currentLocation", "currentQuery"],
    data(){
        return {
        }
    },
    mounted() {
        arrow("newtab");
    },
    methods:{
        canShow(name){
            return  this.which && this.which.endsWith(name)
        },
        onCloseClick(){
            this.onClose && this.onClose(...arguments);
        },
        toNext(tag){
            this.onNext && this.onNext(tag);
        },
    },
    computed:{
        isTutorial() { return this.canShow('tutorial'); },
        isIntro() { return this.canShow('intro'); }
    }
};
</script>
